import React, { useState } from "react";
import { ItemOurWorksComponent } from "./itemOurWorks.component";
import { ItemVideoType } from "../banner";
import img4 from "../../assets/project/safar.jpg";
import img7 from "../../assets/project/tamaddon.png";
import img8 from "../../assets/project/navruzs23ultra.jpg";
import img15 from "../../assets/project/pardifen.png";
import { ourWorksModel1 } from "./model/ourWorks1.model";
import { ourWorks3 } from "./model/ourWorks3.model";
import { OutWorksfullModel } from "./model/outWorksfull.model";
import img33 from "../../assets/project/call.png";
import img34 from "../../assets/project/siyomaashan.png";
import img38 from "../../assets/project/balnica.png";
import img39 from "../../assets/project/lovestore.png";
import img36 from "../../assets/project/image_2023-11-07_22-17-03.png";
import img2 from "../../assets/project/maxresdefault.jpg";
const ourWorksModel2: ItemVideoType[] = [
  {
    img: img36,
    srcVideo: "https://www.youtube.com/embed/Ulf28b6uBUs?si=A0DhS9jg5wTBg6Ep",
    title: "Pier Cardin Cosmetics - TVC Сияй",
    type: "Рекламный ролик",
  },
  {
    img: img7,
    srcVideo: "https://www.youtube.com/embed/VijeT2_X5_s",
    title: "Tamaddoon - ZHIWJ",
    type: "Fashion Film",
  },
  {
    img: img39,
    srcVideo: "https://www.youtube.com/embed/vcSeKtB5Tk4?si=3A3mBXrjMtkUVPEU",
    title: "LoveStory - Dune of Anushervon X Nilufar",
    type: "Love Story",
  },
  {
    img: img33,
    srcVideo: "https://www.youtube.com/embed/ndTu2Z08y48?si=Q1fVN2P_Kf_VPGjq",
    title: "ЕБРР",
    type: "Документальный Фильм",
  },
  {
    img: img34,
    srcVideo: "https://www.youtube.com/embed/C6FxH3qXiFc?si=u4LNI45xpEzd1Ta2",
    title: "Сиёма Молл - Волшебство Шоппинга TVC",
    type: "Рекламный ролик",
  },
  {
    img: img38,
    srcVideo: "https://www.youtube.com/embed/0HkvFHZrIEY?si=Z3JwNQqnYZkdWdNY",
    title: "Primary Health Centre",
    type: "Документальный Фильм",
  },
  {
    img: img2,
    srcVideo: "https://www.youtube.com/embed/Hs6wKpa6OGg",
    title: "Documentary - Health",
    type: "Документальный фильм",
  },
  {
    img: img15,
    srcVideo: "https://www.youtube.com/embed/5Qifle_g3j8",
    title: "Lindavit - TVC",
    type: "Рекламный ролик",
  },
  {
    img: img8,
    srcVideo: "https://www.youtube.com/embed/mybhln-X6fA",
    title: "Navrooz - Shot on Galaxy S23 Ultra",
    type: "Рекламный ролик",
  },

  {
    img: img4,
    srcVideo: "https://www.youtube.com/embed/Cfv3zaNcKi0",
    title: "Samsung - Safarmuhammad New Year",
    type: "Рекламный ролик",
  },
];

export const OurWorksComponent: React.FC = () => {
  const [mobile, setMobile] = useState(true);
  const [show2, setShow2] = useState(false);
  const [hideBtn, setHideBtn] = useState(true);

  const [ourWorksModel1State, setOurWorksModel1State] =
    useState(ourWorksModel1);
  return (
    <>
      <div id="our-works" className="sm:hidden gridProject">
        {ourWorksModel1State.map((item: ItemVideoType) => (
          <ItemOurWorksComponent {...item} key={item.title} />
        ))}
      </div>
      {show2 ? (
        <div className="sm:hidden gridProject">
          {ourWorks3.map((item: ItemVideoType) => (
            <ItemOurWorksComponent {...item} key={item.title} />
          ))}
        </div>
      ) : (
        hideBtn && (
          <button
            onClick={() => {
              setOurWorksModel1State((prev) => [...prev, ...ourWorks3]);
              setHideBtn(false);
            }}
            className="sm:hidden  mt-[8px] w-[450px] mx-auto
       flex items-center justify-center mt-[45px] bg-[#e53012] py-[13px] px-[24px]"
          >
            Еще проектов
          </button>
        )
      )}
      <div className="sm:flex flex items-center justify-center flex-col hidden">
        {mobile
          ? ourWorksModel2.map((item: ItemVideoType) => (
              <ItemOurWorksComponent {...item} key={item.title} />
            ))
          : OutWorksfullModel.map((item: ItemVideoType) => (
              <ItemOurWorksComponent {...item} key={item.title} />
            ))}
        <button
          onClick={() => setMobile(!mobile)}
          className="mt-[8px] mx-[13px]  w-[95%] bg-[#e53012] py-[13px] px-[24px]"
        >
          {mobile ? "Больше проектов" : "Свернуть"}
        </button>
      </div>
    </>
  );
};
