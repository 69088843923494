export const linksModel = [
  {
    title: "ГЛАВНАЯ",
    href: "#home",
  },
  {
    title: "НАШИ РАБОТЫ",
    href: "#our-works",
  },
  {
    title: "СТОИМОСТЬ",
    href: "#price",
  },
  {
    title: "О НАС",
    href: "#about",
  },
  {
    title: "КОНТАКТЫ",
    href: "#contact",
  },
];
